module services {
    export module master {
        export class companyIncotermService implements interfaces.master.ICompanyIncotermService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

          
            getList(): ng.resource.IResourceClass<interfaces.master.ICompanyIncoterm> {
                return this.$resource<interfaces.master.ICompanyIncoterm>(this.ENV.DSP_URL + "CompanyIncoterm/GetList", {
                    IsInbound: '@IsInbound',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getCompanyIncotermDefaults(): ng.resource.IResourceClass<interfaces.master.ICompanyIncotermDefaults> {
                return this.$resource<interfaces.master.ICompanyIncotermDefaults>(this.ENV.DSP_URL + "CompanyIncoterm/GetCompanyIncotermDefaults", {
                    companyId:'@companyId',
                    IsInbound: '@IsInbound',
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
            
            SaveCompanyIncoterms(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CompanyIncoterm/SaveCompanyIncoterms", {
                });
            }

        }
    }
    angular.module("app").service("companyIncotermService", services.master.companyIncotermService);
}